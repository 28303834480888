
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { postAction } from "@/api/claim.api";
import { patientAging } from "@/api/charts.api";
import { accountStatus, getPatient, reviewDate } from "@/api/patient.api";
import { PatientExtended } from "@/modules/patient/patient.model";
import { useStore } from "vuex";
import { getList } from "@/api/dropdown.api";

export interface StatusCode {
  code: number;
  description: string;
}

export interface Data {
  patient?: PatientExtended;
  dict: {
    patientActions: [];
    accountStatuses: [];
  };
  patientChart: {
    series: number[];
    chartOptions: any;
    chartData: any[];
    noServices: boolean;
  };
  insuranceChart: {
    series: number[];
    chartOptions: any;
    chartData: any[];
    noServices: boolean;
  };
}

export default defineComponent({
  name: "PatientPage",
  props: ["patientId"],
  components: { Multiselect },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      patient: undefined,
      dict: {
        patientActions: [],
        accountStatuses: [],
      },
      patientChart: {
        series: [],
        chartOptions: {},
        chartData: [],
        noServices: true,
      },
      insuranceChart: {
        series: [],
        chartOptions: {},
        chartData: [],
        noServices: true,
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageBreadcrumbs("Patient Account", [
        {
          title: "Patient Accounts",
          path: "back",
          buttonTitle: "Back",
        },
      ]);

      data.dict.patientActions = store.getters.allPatientActions;
      data.patient = await getPatient(props.patientId);
      data.dict.accountStatuses = await getList({ type: "Account Status" });

      const agingData = await patientAging(props.patientId);
      data.patientChart.chartData = agingData.patientData;
      data.insuranceChart.chartData = agingData.insuranceData;

      data.patientChart.series = data.patientChart.chartData.map((item) => {
        if (item.totalBalance > 0) {
          data.patientChart.noServices = false;
        }

        return item.totalBalance;
      });

      data.insuranceChart.series = data.insuranceChart.chartData.map((item) => {
        if (item.totalBalance > 0) {
          data.insuranceChart.noServices = false;
        }

        return item.totalBalance;
      });

      data.patientChart.chartOptions = {
        legend: {
          offsetX: 0,
          horizontalAlign: "left",
        },
        plotOptions: {
          pie: {
            pie: {
              size: 200,
            },
            donut: {
              size: "20%",
            },
          },
        },
        chart: {
          type: "donut",
        },
        labels: data.patientChart.chartData.map((item) => {
          return item.label;
        }),
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              "<div class='arrow_box' style='background-color:" +
              w.globals.colors[seriesIndex] +
              "'>" +
              '<div style="margin: 4px">' +
              "<span>" +
              getPatientAgingItemByIndex(seriesIndex).label +
              "</span>" +
              "</div>" +
              '<div style="margin: 4px">' +
              "<span>" +
              getPatientAgingItemByIndex(seriesIndex).servicesCount +
              " services; $" +
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
              }).format(getPatientAgingItemByIndex(seriesIndex).totalBalance) +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
      };

      data.insuranceChart.chartOptions = {
        legend: {
          offsetX: 0,
          horizontalAlign: "left",
        },
        plotOptions: {
          pie: {
            pie: {
              size: 200,
            },
            donut: {
              size: "20%",
            },
          },
        },
        chart: {
          type: "donut",
        },
        labels: data.insuranceChart.chartData.map((item) => {
          return item.label;
        }),
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              "<div class='arrow_box' style='background-color:" +
              w.globals.colors[seriesIndex] +
              "'>" +
              '<div style="margin: 4px">' +
              "<span>" +
              getInsuranceAgingItemByIndex(seriesIndex).label +
              "</span>" +
              "</div>" +
              '<div style="margin: 4px">' +
              "<span>" +
              getInsuranceAgingItemByIndex(seriesIndex).servicesCount +
              " services; $" +
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
              }).format(
                getInsuranceAgingItemByIndex(seriesIndex).totalBalance
              ) +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
      };
    });

    function getPatientAgingItemByIndex(index) {
      return data.patientChart.chartData[index];
    }

    function getInsuranceAgingItemByIndex(index) {
      return data.insuranceChart.chartData[index];
    }

    const localActions = [1, 0, 2, 3, 4, 5];
    function localActionHandler(action) {
      if (action == 0) {
        router.push({
          path: "/Payments/patientPayment/",
          query: { patientId: props.patientId, type: 0 },
        });
      }
      if (action == 1) {
        router.push({
          path: "/statements/",
          query: {
            patient: data.patient?.lastName + ", " + data.patient?.firstName,
          },
        });
      }
      if (action == 3) {
        router.push({
          path: "/Payments/patientPayment/",
          query: { patientId: props.patientId, type: 1 },
        });
      }
      if (action == 2) {
        router.push({
          path: "/Payments/UnappliedCredits",
          query: {
            globalSearch:
              data.patient?.lastName + ", " + data.patient?.firstName,
          },
        });
      }
      if (action == 4) {
        router.push({
          path: "/Payments/patientRefund/",
          query: { patientId: props.patientId },
        });
      }
      if (action == 5) {
        router.push({
          path: `/patientAccounts/patient/${props.patientId}/superbill`,
        });
      }
    }

    async function sendAction(action) {
      if (localActions.includes(action)) {
        localActionHandler(action);
        return;
      }
      const res = await postAction({
        patientId: [data.patient?.id],
        patientAction: action,
      });
    }
    async function refreshPatient() {
      data.patient = await getPatient(props.patientId);
    }

    async function reviewDateChanged(date) {
      await reviewDate(props.patientId, date);
    }

    async function accountStatusChanged(accountSt) {
      await accountStatus(props.patientId, accountSt);
    }

    const noServicesChartOptions = {
      legend: {
        offsetX: 0,
        horizontalAlign: "left",
      },
      plotOptions: {
        pie: {
          pie: {
            size: 200,
          },
          donut: {
            size: "20%",
          },
        },
      },
      chart: {
        type: "donut",
      },
      labels: ["No Balance"],
      colors: ["#909399"],
      tooltip: {
        enabled: false,
      },
    };

    return {
      sendAction,
      refreshPatient,
      organizationId,
      reviewDateChanged,
      accountStatusChanged,
      data,
      noServicesChartOptions,
    };
  },
});
